


















































import { ApiService, AxiosFactory } from '@/core/services';
import { StudyGuidanceFileModel } from '@/core/webapi';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputTextareaCpt,
  InputSelectCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    InputFieldCpt,
    InputTextareaCpt,
    InputSelectCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class StudyManageGuidanceView extends BaseFormComponent {
  model = new StudyGuidanceFileModel({ modelState: {} } as any);
  loading = true;

  get studyId() {
    return parseInt(this.$route.params.studyId, 10);
  }

  get locale() {
    return this.$route.params.locale;
  }

  async created() {
    try {
      const data = (await ApiService.studies().getGuidanceDoc(this.studyId, this.locale)).data;

      if (!!data) {
        this.model = data;
      } else {
        this.model.studyId = this.studyId;
        this.model.locale = this.locale;
      }
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    this.loading = true;
    try {
      const formData = new FormData();
      Object.keys(this.model).forEach(key => formData.append(key, (this.model as any)[key]));

      const result = await AxiosFactory.create()
        .post('/api/v1/studies/guidance', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Item saved') as string,
        type: 'success',
      });

      this.$router.push('/admin/studies');
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async onFileSelected(file: any) {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      this.model.fileName = file.file.name;
      this.model.base64File = e.target.result;
    };

    reader.readAsDataURL(file.file);
  }

  removeFile() {
    this.model.fileUrl = '';
    this.model.fileName = '';
    this.model.base64File = '';
  }
}
