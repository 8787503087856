



















































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { AutoCompleteItem } from '@/core/models';
import { StudyFinderDialogCpt } from '@/core/components';
import { ApiService } from '@/core/services';
import { StudyCollectionModel } from '@/core/webapi';

@Component({
  components: {
    StudyFinderDialogCpt,
  },
})
export default class OrganisationStudiesCpt extends AppVue {
  @Prop() organisationId: number;

  items: StudyCollectionModel[] = [];
  studyTypeId = 0;
  showDialog = false;

  async created() {
    await this.initializeData();
  }

  async removeItem(id: number) {
    if (
      !confirm(
        this.$t(
          'Are you sure you want to remove the organisation from this study? ' +
            'This will also disassociate all the reporters from this study.',
        ) as string,
      )
    ) {
      return;
    }

    await ApiService.studies().removeOrganisationFromStudy(id, this.organisationId);
    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Organisation removed from study') as string,
      type: 'success',
    });
    await this.initializeData();
  }

  async find() {
    const response = await ApiService.organisations().getOrganisation(this.organisationId);
    this.studyTypeId = response.data.studyTypeId;
    this.showDialog = true;
  }

  async onStudySelected(foundItem: AutoCompleteItem) {
    if (_.find(this.items, item => item.id === foundItem.id)) {
      this.$notify({
        title: this.$t('Warning') as string,
        message: this.$t('Organisation is already part of this study') as string,
        type: 'warning',
      });
      return;
    }

    await ApiService.studies().addOrganisationToStudy(foundItem.id, this.organisationId);
    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Organisation added to study') as string,
      type: 'success',
    });
    await this.initializeData();
  }

  closeDialog() {
    this.showDialog = false;
  }

  private async initializeData() {
    this.items = (await ApiService.organisations().getOrganisationStudies(this.organisationId)).data;
  }
}
