


























































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { GridFactory, GridModel, GridFilter, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';
import { OrganisationCollectionModel } from '@/core/webapi';
import { ReporterInviteLinkReporterCpt } from '@/modules/access-requests/components';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    ReporterInviteLinkReporterCpt,
  },
})
export default class OrganisationsGridView extends AppVue {
  grid = {} as GridModel<OrganisationCollectionModel>;
  showInviteDialog = false;
  inviteOrganisationId = 0;

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.organisations().filterOrganisations(filter),
      routeName: 'Organisations',
      defaultFilterOverrides: new GridFilter(),
    });
  }

  openInviteDialog(organisationId: number) {
    this.inviteOrganisationId = organisationId;
    this.showInviteDialog = true;
  }

  closeInviteDialog() {
    this.showInviteDialog = false;
    this.inviteOrganisationId = 0;
  }
}
