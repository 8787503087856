

















































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { LOCALES } from '@/i18n';
import { ApiService } from '@/core/services';
import { GridFactory, GridModel, GridFilter, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';
import { StudyCollectionModel, StudyGuidanceFileModel } from '@/core/webapi';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
  },
})
export default class StudiesGridView extends AppVue {
  grid = {} as GridModel<StudyCollectionModel>;

  get langs() {
    return LOCALES;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.studies().filterStudies(filter),
      routeName: 'Studies',
      defaultFilterOverrides: new GridFilter(),
    });
  }

  isLangSet(studyGuidances: StudyGuidanceFileModel[], locale: string) {
    return !!studyGuidances.find(p => p.locale === locale);
  }
}
