import { render, staticRenderFns } from "./AnalystDashboardView.vue?vue&type=template&id=552a4d5e&scoped=true&"
import script from "./AnalystDashboardView.vue?vue&type=script&lang=ts&"
export * from "./AnalystDashboardView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552a4d5e",
  null
  
)

export default component.exports