































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { UserCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { GridFactory, GridModel, GridFilter, GridSearchCpt, GridPagerCpt } from '@/core/components/grid';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
  },
})
export default class UsersGridView extends AppVue {
  grid = {} as GridModel<UserCollectionModel>;

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.users().filterUsers(filter),
      routeName: 'adminUserList',
    });
  }

  formatList(roles: string[]) {
    return roles.join(', ') || '-';
  }

  async unlock(userId: number) {
    await ApiService.account().unlock(userId);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('User unlocked') as string,
      type: 'success',
    });

    this.grid.update();
  }

  async resendConfirmationEmail(userId: number) {
    await ApiService.account().resendConfirmationEmail(userId);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('User confirmation email re-sent') as string,
      type: 'success',
    });
  }

  async viewSmsCode(userId: number) {
    const code = (await ApiService.account().getSmsCode(userId)).data;
    const message = this.$t('Temporary authentication code:');
    const title = this.$t('Auth Code') as string;

    this.$alert(`${message} <strong>${code}</strong>`, title, {
      confirmButtonText: 'OK',
      dangerouslyUseHTMLString: true,
    });
  }

  async set2FaEnforcing(userId: number, setToValue: boolean) {
    await ApiService.account().set2FaEnforcing(userId, { setToValue });

    const message = setToValue ? this.$t('Authenticator enforced') : this.$t('Authenticator disabled');

    this.$notify({
      title: this.$t('Success') as string,
      message: message as string,
      type: 'success',
    });

    this.grid.update();
  }

  async reset2Fa(userId: number) {
    await ApiService.account().resetAuthenticator(userId);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Authenticator reset') as string,
      type: 'success',
    });

    this.grid.update();
  }
}
