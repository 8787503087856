






























































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import {
  BaseFormComponent,
  InputFieldCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  ValidationSummaryCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { UserAccountLookupModel, UserAccountModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { GameFormats, StudyTypes } from '@/core/constants';
import { InputOrganisationsCpt, InputOrganisationStudiesCpt } from '@/modules/admin/users/components';

@Component({
  components: {
    InputFieldCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    InputOrganisationsCpt,
    InputOrganisationStudiesCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class UserFormView extends BaseFormComponent {
  get userId() {
    return +this.$route.params.userId;
  }

  lookups = {} as UserAccountLookupModel;
  model = new UserAccountModel({ modelState: {} } as any);
  loading = true;
  eliteId = StudyTypes.Elite;
  communityId = StudyTypes.Community;

  get disableForStudyAdmin() {
    return this.isStudyAdmin && !!this.model.id;
  }

  get playingPositions15s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Fifteens);
    });
  }

  get playingPositions7s() {
    if (!this.lookups || !this.lookups.playingPositions) {
      return [];
    }

    return _.filter(this.lookups.playingPositions, position => {
      return _.includes(position.gameFormatIds, GameFormats.Sevens);
    });
  }

  get showReporterOrgs() {
    if (!this.model || !this.lookups) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    //model && model.reporterOrganisations && lookups

    // This is basically just for study admins
    return !!this.model.reporterOrganisations?.length;
  }

  async created() {
    try {
      this.lookups = (await ApiService.users().getManageUserFormLookupData()).data;
      await this.initializeModel();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    try {
      this.userId
        ? await ApiService.users().updateAccount(this.model).handleValidationErrors(this.model)
        : await ApiService.users().createAccount(this.model).handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('User saved') as string,
        type: 'success',
      });

      this.isReporter ? this.$router.push('/players') : this.$router.push('/admin/users');
    } finally {
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  onReporterOrganisationsChanged(selectedIds: number[]) {
    this.model.reporterOrganisations =
      this.model.reporterOrganisations?.filter(p => selectedIds.includes(p.organisationId)) ?? [];

    selectedIds.forEach(selectedId => {
      if (!this.model.reporterOrganisations!.find(p => p.organisationId === selectedId)) {
        const organisation = this.lookups.organisations!.find(p => p.id === selectedId);
        if (!organisation) {
          return;
        }

        this.model.reporterOrganisations!.push({
          organisationId: organisation.id,
          organisationName: organisation.name,
          studyTypeId: organisation.studyTypeId,
          allowMaleData: false,
          allowFemaleData: false,
          allowInjuryAndIllnessData: false,
          allowExposureData: false,
          allowCatastrophicData: false,
          studies: [],
        });
      }
    });
  }

  private async initializeModel() {
    if (this.userId) {
      const model = (await ApiService.users().getAccount(this.userId)).data;
      _.extend(this.model, model);
    } else {
      if (this.lookups.organisations!.length === 1) {
        this.model.playerOrganisationId = this.currentOrganisationId as any;
      }
      if (this.lookups.roles!.length === 1) {
        this.model.roleId = this.lookups.roles![0].id;
      }
    }
  }
}
